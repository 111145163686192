import React from "react"
import { useSelector } from "react-redux"

const MainSection = () => {
  const { language } = useSelector(state => state.global)
  const { preTitle, title, highlightedText, text, blocks } = useSelector(
    ({ contact }) => contact.banner
  )

  return (
    <div className="banner about_banner contact_banner">
      <div className="container">
        {/* <span className="meyil">{preTitle[language]}</span> */}
        <div className="banner_txt">
          <div>
            <h1 className="split-text">{title[language]} </h1>
            <p className="split-text">
              <span className="banner_txtclr">{highlightedText[language]}</span>
              {text[language]}
            </p>
          </div>

          <div className="contact_info_box">
            {blocks.map(item => (
              <div className="contact_info">
                <div>
                  <h6>{item.title[language]}</h6>
                  <h3>{item.text}</h3>
                </div>
                <div>
                  <i className="arrow arrowcnt" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSection
