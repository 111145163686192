import React, { useState } from "react"

const MultiDropdown = ({
  options,
  selectedOptions,
  onSelect,
  name,
  validation,
  language,
}) => {
  const [toggle, setToggle] = useState(false)
  return (
    <dl className="dropdown" onClick={() => setToggle(!toggle)}>
      <dt>
        <textarea
          name={name[language]}
          id=""
          {...validation}
          cols="46"
          rows="2"
          value={selectedOptions.join(",")}
        />
      </dt>
      <dd>
        <div className="mutliSelect">
          <ul style={{ display: toggle ? "block" : "none" }}>
            {options.map(option => (
              <li>
                <div className="select_card">
                  <label className="container-checkbox">
                    <input
                      type="checkbox"
                      value={option.value[language]}
                      onChange={e => onSelect(e)}
                    />
                    <span className="checkmark" />
                    <h3>{option.label[language]}</h3>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </dd>
    </dl>
  )
}

export default MultiDropdown
