import React from "react"
import MainSection from "./MainSection"
import ContactForm from "./ContactForm"
import Locations from "./Locations"

const ContactUs = () => {
  return (
    <div className="contact-us">
      <MainSection />
      <ContactForm />
      <Locations />
    </div>
  )
}

export default ContactUs
