import React from "react"
import { useSelector } from "react-redux"

const Locations = () => {
  const { language } = useSelector(state => state.global)
  const { addresses, text, title } = useSelector(
    ({ contact }) => contact.location
  )

  return (
    <section className="location_main">
      <div className="location_status">
        <div className="section_pad">
          <div className="text_side p-0">
            <div className="txt_block w-100">
              <div className="lftbrdr">
                <h6>{title[language]}</h6>
              </div>
            </div>
          </div>
          <div className="loc_two">
            {addresses.map(address => (
              <div className="location_section">
                <i className="location_icon" />
                <h3>{address.title[language]}</h3>
                <p className="split-text">{address.text[language]}</p>
                <a href={`${address.coOrdinates}`} target="__blank">
                  {address.anchorText[language]}
                  <i className="arrow arrowtwo" />
                </a>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="location_sectionimg">
               <div className='split-text'>{text}</div>
            </div> */}
      </div>
    </section>
  )
}

export default Locations
