import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactPageContent from "../components/ContactUs"
import { graphql, StaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import {
  CONTACT_PAGE_QUERY_REQUEST,
  CONTACT_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes/index"
const ContactUs = () => {
  const dispatch = useDispatch()

  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToxNTY=") {
          contactus_contents {
            banner {
              blocks {
                title {
                  en
                  sv
                }
                text
              }
              highlightedText {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            enquiryForm {
              form {
                errorMessage {
                  en
                  sv
                }
                label {
                  en
                  sv
                }
                name
                options {
                  label {
                    en
                    sv
                  }
                  value {
                    en
                    sv
                  }
                }
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            formButtons {
              label {
                en
                sv
              }
              nextButton {
                en
                sv
              }
              previousButton {
                en
                sv
              }
              submitButton {
                en
                sv
              }
            }
            location {
              addresses {
                anchorText{
                  en
                  sv
                }
                coOrdinates
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: CONTACT_PAGE_QUERY_REQUEST })
        dispatch({ type: CONTACT_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <Layout>
            <SEO title="Contact-Us" pageId />
            <ContactPageContent />
          </Layout>
        )
      }}
    />
  )
}

export default ContactUs
